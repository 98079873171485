<template lang="pug">
v-navigation-drawer.app-nav-drawer(v-model="isActive" app width="100%")
  .app-nav-drawer__wrapper
    .app-nav-drawer__header
      v-toolbar.sh-none(plain)
        ui-icon.c-black70(name="close" @click="close")

    .app-nav-drawer__content
      user-panel.app-nav-drawer__user-panel(@signout="signOut")

      v-list.py-0(nav)
        v-list-item-group
          v-list-item.mt-0(active-class="v-list-item--catalog" @click="openCatalogDialog")
            v-list-item-icon
              ui-icon.c-black70(name="grid-view")
            v-list-item-title.d-flex.justify-space-between
              .t-normal-bold.c-gray100 Каталог
              ui-icon.mr-2(name="arrow-outline-right")
          v-divider

          v-list-item(:to="$path.services()" @click="close")
            v-list-item-icon
              ui-icon.c-black70(name="case-check")
            v-list-item-title
              .t-normal-bold.c-gray100 Сервисы для покупателей
          v-list-item(:to="$path.tariff()" @click="close")
            v-list-item-icon
              ui-icon.c-black70(name="cart")
            v-list-item-title
              .t-normal-bold.c-gray100 Для продавцов
          v-divider

          v-list-item(v-if="currentUser" :to="$path.myFavorites()" @click="close")
            v-list-item-icon
              ui-icon.c-black70(name="bookmark")
            v-list-item-title
              .t-normal-bold.c-gray100 Избранное
          v-list-item(v-if="currentUser" :href="$path.myAssets()" @click="close")
            v-list-item-icon
              ui-icon.c-black70(name="asset")
            v-list-item-title
              .t-normal-bold.c-gray100 Мои активы
          v-list-item(v-if="currentUser" :href="$path.myDashboards()" @click="close")
            v-list-item-icon
              ui-icon.c-black70(name="asset")
            v-list-item-title
              .t-normal-bold.c-gray100 Статистика
          v-list-item(@click="openGoWizardDialog")
            v-list-item-icon
              ui-icon.c-black70(name="add-round")
            v-list-item-title
              .t-normal-bold.c-gray100 Разместить актив
          template(v-if="currentUser")
            v-list-item(:to="$path.myAuctions()" @click="close")
              v-list-item-icon
                ui-icon.c-black70(name="auction")
              v-list-item-title
                .t-normal-bold.c-gray100 Мои аукционы
      v-divider
      v-list.py-0(nav)
        v-list-item(:to="$path.about()" @click="close")
          v-list-item-title.ml-8
            .t-normal.c-gray100 О проекте
        v-list-item(:to="$path.blog()" @click="close")
          v-list-item-title.ml-8
            .t-normal.c-gray100 Блог
        v-list-item(:to="$path.faq()" @click="close")
          v-list-item-title.ml-8
            .t-normal.c-gray100 Помощь

        template(v-if="currentUser")
          v-list-item(@click="signOut")
            v-list-item-icon
              ui-icon.c-black70(name="exit")
            v-list-item-title
              .t-normal.c-gray100 Выйти

    .app-nav-drawer__footer
      get-apps

  catalog-dialog(
    ref="catalogDialog"
    button-text="Показать активы"
    key="app-drawer-catalog-dialog"
    :purpose.sync="purpose"
  )
</template>

<script>
  import { UiIcon } from '@/uikit/';
  import CatalogDialog from '@/components/CatalogDialog.vue';
  import UserPanel from '@/components/user/UserPanel.vue';
  import GetApps from '@/components/app/GetApps.vue';
  import { mapGetters } from 'vuex';

  import { createLogger } from '@/uikit/util';
  const logger = createLogger('AppNavDrawer', 'orange');

  export default {
    name: 'app-nav-drawer',
    components: { UiIcon, UserPanel, CatalogDialog, GetApps },
    inject: ['openGoWizardDialog'],
    
    props: {
      value: { type: Boolean, default: false },
    },

    data() {
      return {
        purpose: undefined,
      };
    },

    computed: {
      isActive: {
        set(value) {
          this.$emit('input', value);
        },
        get() {
          return this.value;
        },
      },

      kind: {
        set(kind) {
          this.$store.dispatch('catalog/set_kind', kind);
        },
        get() {
          return this.$store.state.catalog.kind;
        },
      },

      kindTitle() {
        const kind = this.kind ? this.$store.getters['categories/find'](this.kind) : null;
        return kind ? kind.title : undefined;
      },

      ...mapGetters(['currentUser']),
    },

    mounted() {
      this.purpose = this.$store.state['catalog'].placementPurpose || 'buy';
    },

    methods: {
      close() {
        this.isActive = false;
      },

      async openCatalogDialog() {
        try {
          const kind = await this.$refs.catalogDialog.open(this.kind, this.kindTitle);
          if (kind) {
            this.kind = kind;
            this.$store.dispatch('catalog/reset_filters_values');
            this.close();
            this.$router.push(this.$path.catalog(kind, this.purpose));
          }
        } catch (error) {
          logger.error(error);
        }
      },

      signOut() {
        try {
          this.$store.dispatch('user/signOut');
          this.close();
          this.$router.push(this.$path.home());
          this.$notify.info('Вы вышли из системы');
        } catch (error) {
          logger.error(error);
        }
      },
    },
  };
</script>

<style lang="scss">
  .app-nav-drawer {
    .app-nav-drawer__wrapper {
      display: flex;
      flex-direction: column;
      height: 100dvh;
    }

    .app-nav-drawer__content {
      flex-grow: 1;
      overflow-y: auto;
      min-height: 0;
    }

    .app-nav-drawer__header {
      flex: 0 1 48px;
      flex-shrink: 1;
    }

    .app-nav-drawer__footer {
      flex-shrink: 1;
      padding: 16px 24px 24px;
    }

    .app-nav-drawer__user-panel {
      margin: 8px 16px 14px;
    }

    .v-list-item__icon {
      margin: 0 !important;
      margin-right: 8px !important;
      align-self: auto;
    }

    .v-list-item {
      margin: 2px 0 !important;
      padding: 0 !important;

      &.v-list-item--active {
        &::before {
          display: none;
        }

        .icon {
          color: c('primary60', 'light') !important;
        }

        .v-list-item__title {
          color: c('primary60', 'light');
        }
        &.v-list-item--catalog {
          .icon {
            color: c('black70', 'light') !important;
          }

          .v-list-item__title {
            color: c('gray100', 'light');
          }
        }
      }
    }
    .v-list--nav {
      padding-left: 16px !important;
      padding-right: 16px !important;
    }
  }
</style>
